@import "../../styles/color";

.selectPayDocPage {
  &_payDoc {
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
    &-num {
      font-weight: bold;
    }
    &:hover {
      box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    }
  }
  &_newPayDocForm {
    margin-top: 20px;
    &-input {
      margin-bottom: 10px;
    }
  }
}