@import "../../styles/color";

.facilitiesVisit {
  &__list {
    background-color: $white;
    border: 1px solid $grey;

    .facility__name {
      margin-top: 10px;
      font-size: 1rem;
    }
  }
}


.facility__map {
  height: 197px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 20px;
}

.facilityInfo {
  padding-top: 20px;
  padding-bottom: 20px;

  .row {
    border-bottom: solid 1px $grey;
    margin-bottom: 10px;
  }

  .facilityInfo__totalSum {
    text-align: right;
    padding-bottom: 15px;

    .totalSum__link {
      text-decoration: none;
    }

    .totalSum_blockPrice {
      min-width: 50%;
      background-color: $green;
      border-radius: 5px;
      text-align: center;
      height: 100%;
      font-weight: 600;
      color: $white;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-decoration: none;
      cursor: pointer;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      transition: box-shadow 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      }

      svg {
        font-size: 30px;
      }

      .totalSum__text {
        display: block;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .facilityInfo__blockDate {
    .blockDate__date,
    .blockDate__time,
    .blockDate__address {
      display: inline-block;
      width: 100%
    }

    .blockDate__time {
      font-size: 1.5em;
    }
  }
}