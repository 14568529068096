@import '../../styles/color';

.content.facilitySearch {
  padding-right: 5px;

  .facilitySearch__input {
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid $grey;
    outline: 0;
    padding: 5px 10px;
    background-color: $beige;
  }
}

.content.facilityList {
  padding-bottom: 70px;

  .facilityItem {
    border-bottom: 1px solid $grey;
    padding: 5px 5px 5px 15px;

    .facilityItem__content {
      overflow: hidden;
      transition: all 0.5s linear;
      min-height: 0;

      &.facilityItem__content_hided {
        height: 0px;
      }
    }

    .facilityItem__title {
      font-weight: 600;
      cursor: pointer;
      width: 100%;
      display: block;
      padding: 5px 0 5px;
      text-transform: uppercase;
    }

    .facilityItem__arrow {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $greyBrown;
      font-size: 20px;
      opacity: .66;
      cursor: pointer;
      z-index: 1;
    }

    .facilityItemName {
      display: block;
      width: 100%;
      padding: 10px 10px 10px 40px;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: $grey;
      }

      .facilityItemName__checkbox {
        position: absolute;
        top: 10px;
        left: 9px;
        border: 2px solid $dartGray;
        width: 22px;
        height: 22px;
      }
    }

    .facilityItem__price {
      display: block;
      width: 100%;
      border-top: 1px solid $grey;
      padding: 10px;
      font-weight: 600;
      color: $dartGray;
    }

    .facilityItem__block {
      border: 1px solid $grey;
      margin-bottom: 10px;
      background-color: $white;
    }
  }
}

.content.facilitySelected {
  padding-right: 5px;

  .facilitySelected__container {
    border: 1px solid $grey;
    padding: 10px;
    background-color: $white;
  }

  .facilitySelected__title {
    font-weight: 600;
    padding: 5px;
  }

  .facilitySelected__item {
    padding: 5px;
    position: relative;
  }

  .facilitySelectedItem__iconDelete {
    position: absolute;
    right: 0;
    top: 9px;
    color: $yellow;
    cursor: pointer;
  }
}

.searchService {
  padding-top: 20px;
  padding-bottom: 10px;

  .searchService__input {
    width: 100%;
    background-color: $beige;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    outline: none;
  }
}

.bottomPanel {
  background-color: $lightGrey;
  padding: 15px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;

  .bottomPanel__totalServices {
    width: 100%;
    border-bottom: 1px solid $grey
  }

  .bottomPanel__button,
  .bottomPanel__totalSum {
    width: 100%;
  }

  .bottomPanel__buttonBlock{
    display: flex;
    align-items: center;
  }
}

.pageFacility {
  position: relative;
}