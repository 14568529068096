@import '../../styles/color';

.updateVisit__container {
  padding-top: 20px;
}

.bottomPanel {
  background-color: $lightGrey;
  padding: 15px;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;

  .bottomPanel__button,
  .bottomPanel__totalSum {
    width: 100%;
  }

  .bottomPanel__buttonBlock{
    display: flex;
    align-items: center;
  }
}
