@import "../../../styles/color";

.contentHeader{
  background-color: $purple;
  height: 65px;
  position: relative;

  .homeLogo__img {
    height: 93%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.contentTitle{
  color: $golden;
  background-image: url('30ab368b7c17c48_20190219185531.jpg');
  height: 120px;
  box-sizing: border-box;
  background-size: cover;
  position: relative;


  .title{
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .shade{
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    background-color: #212121;
    opacity: 0.8;
  }

  .visitInfo {
    position: absolute;
    bottom: 0;
  }
}

.arrowLeft{
  text-align: right;

  .arrowLeft__link{
    color: $golden;
    cursor: pointer;
    top: 50%;
    right: 15px;
    position: absolute;
    margin-top: -10px;
    font-size: 19px;
  }
}

.errorText{
  text-align: center;
  color: $red;
  padding-top: 5px;
}