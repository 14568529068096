$greyBrown: #212121;
$golden: #ffecb3;
$grey: rgba(0,0,0,.12);
$beige: #FAFAFA;
$dartGray: #9E9E9E;
$white: #fff;
$yellow: #ffc107;
$dartYellow: #dda806;
$lightGrey: #E0E0E0;
$red: #F00;
$purple: #5a305d;
$green: #28a745;