@import '../../styles/color';

.timingVisit__container {
  padding-top: 20px;
}

.timingVisit__item {
  display: inline-block;
}

.timingVisitItem {
  width: 25%;
  padding: 5px;

  .timingVisitItem__content {
    text-align: center;
    line-height: 2;
    border: 1px solid $grey;
    background-color: $white;
    cursor: pointer;
  }
}