@import "../../styles/color";

.formRegistration {
  .formRegistration__containerInput{
    margin-top: 20px;
  }

  .react-tel-input .formRegistration__btnRegion {
    border-top:0;
    border-left:0;
    border-right:0;
    border-radius:0;
    background-color:$beige;

    &:hover{
      background-color:$beige;

      .selected-flag {
        background-color:$beige;
      }
    }
  }

  input.formRegistration__input,
  input[type=tel].formRegistration__input{
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    background-color: $beige;
    margin-top: 20px;
    width: 100%;

    &:focus {
      background-color: $beige;
      outline: none;
      box-shadow: 0 4px 2px -2px $grey;
      border-color: $grey;
    }
  }

  input.formRegistration__input_notFound{
    color: $red;
  }

  input.referralPartnerBlock__input{
    padding-right: 35px;
  }

  .formRegistration__label{
    padding-left: 10px;
    padding-top: 7px;
  }

  .referralPartnerBlock{
    position: relative;

    .referralPartnerBlock__spinner{
      position: absolute;
      top: 10px;
      right: 0;
      display:none;
      height: 1.5rem;
      width: 1.5rem;
      color: $golden;
    }

    .referralPartnerBlock__spiner_search{
      display: block;
    }

    .formRegistration__alert{
      position: absolute;
      top: -3px;
      left: 13px;
      font-size: 10px;
      color: $red;
      display: none;
    }

    .formRegistration__alert_notFound{
      display: block;
    }
  }

  .formRegistration__btn {
    margin-top: 20px;
  }
}