@import "../../styles/color";

.contentHeader{
  background-color: $greyBrown;
  height: 65px;
  position: relative;

  .logo {
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.contentTitle{
  color: $golden;
  background-image: url('../common/header/30ab368b7c17c48_20190219185531.jpg');
  height: 120px;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  padding-top: 80px;


  .title{
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .shade{
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    background-color: #212121;
    opacity: 0.8;
  }
}

.content {
  padding-top: 15px;

  .content__title {
    font-size: 23px;
    font-weight: 600;
    text-align: center;
    display: block;
  }

  .parlourList__item {
    list-style-type: none;
    margin: 5px 0 5px;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .parlourItem {
    border: 1px solid $grey;
    padding: 5px;
    position: relative;
    cursor: pointer;
    background-color: $white;

    .parlourItem__map {
      width: 100%
    }

    .parlourItem__address,
    .parlourItem__name{
      display: block;
      width: 100%;
    }

    .parlourItem__arrowRight{
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $greyBrown;
      font-size: 33px;
      opacity: .66;
    }
  }
}