@import "../../styles/color";

.react-calendar.calendarCustom {
  width: 100%;
  margin-top: 30px;
  border: 0px;
  background-color: $beige;

  .react-calendar__navigation {
    font-size: 1.9em;
    .react-calendar__navigation__label {
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
}