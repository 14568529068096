@import "color";

html {
  overflow-y: scroll;
}

.important{
  color: $red;
}

.content__title {
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  display: block;
}